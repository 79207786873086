import React from 'react';
import { graphql } from 'gatsby';
import { Container, Row, Col } from 'emotion-flex';
import SEO from '../components/SEO';
import { ContentfulStaticPage } from '../graphql-types';
import { withLayout } from '../components/Layout';
import styled from '@emotion/styled';
import hexToRgba from 'hex-to-rgba';
import { SYSTEM_FONTS } from '../styles/typography';

interface Props {
  pageContext: {
    locale: string;
  };
  data: {
    contentfulStaticPage: ContentfulStaticPage;
  };
}

const StaticPageContainer = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${['Blacker Pro Display'].concat(SYSTEM_FONTS).join(', ')};
    color: #160b2c;
  }

  h1,
  h2 {
    margin-bottom: 40px;
  }

  p {
    font-family: ${['Open Sans'].concat(SYSTEM_FONTS).join(', ')};
    color: ${hexToRgba('#160B2C', 0.8)};
    line-height: 30px;
  }
`;

const StaticPageTemplate: React.FC<Props> = ({
  pageContext: { locale },
  data,
}) => (
  <>
    <SEO
      title={data.contentfulStaticPage.seoTitle}
      description={data.contentfulStaticPage?.seoDescription}
      lang={locale}
    />
    <section css={{ padding: '48px 0' }}>
      <Container>
        <Row>
          <Col lg={8} lgOffset={2}>
            <StaticPageContainer
              dangerouslySetInnerHTML={{
                __html:
                  data.contentfulStaticPage.content.childMarkdownRemark.html,
              }}
            />
          </Col>
        </Row>
      </Container>
    </section>
  </>
);

export const staticPageQuery = graphql`
  query staticPageQuery($id: String!) {
    contentfulStaticPage(id: { eq: $id }) {
      seoTitle
      seoDescription
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;

export default withLayout(StaticPageTemplate, { isNewLayout: true });
